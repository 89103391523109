// Generated by Framer (013b13c)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-syJCk"

const variantClassNames = {y7XCKLcsd: "framer-v-1jsk2i8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "y7XCKLcsd", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1sss85u = activeVariantCallback(async (...args) => {
await delay(() => setVariant("plUZDgCR1"), 2000)
})

useOnVariantChange(baseVariant, {default: onAppear1sss85u})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 720, pixelWidth: 842, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/RnHGki1lbpEMLdwalemwI42e6s.webp", srcSet: "https://framerusercontent.com/images/RnHGki1lbpEMLdwalemwI42e6s.webp?scale-down-to=512 512w,https://framerusercontent.com/images/RnHGki1lbpEMLdwalemwI42e6s.webp 842w"}} className={cx(scopingClassNames, "framer-1jsk2i8", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"y7XCKLcsd"} ref={refBinding} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, filter: "blur(0px)", WebkitFilter: "blur(0px)", ...style}}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-syJCk.framer-1y12ofv, .framer-syJCk .framer-1y12ofv { display: block; }", ".framer-syJCk.framer-1jsk2i8 { height: 420px; overflow: hidden; position: relative; width: 379px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 420
 * @framerIntrinsicWidth 379
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergEi9pGO5s: React.ComponentType<Props> = withCSS(Component, css, "framer-syJCk") as typeof Component;
export default FramergEi9pGO5s;

FramergEi9pGO5s.displayName = "Imange 1";

FramergEi9pGO5s.defaultProps = {height: 420, width: 379};

addFonts(FramergEi9pGO5s, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})